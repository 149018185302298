.ffx-multiselect {
  @extend .w-100;

  .p-multiselect-label {
    @extend .d-flex, .flex-wrap, .p-0, .w-100;
  }

  .p-multiselect-label-container {
    @extend .d-inline-flex, .py-1, .px-2, .my-0, .mx-1;
  }

  .p-overlay.p-component {
    @extend .top-100;
  }

  .p-multiselect-trigger .p-icon-wrapper {
    color: var(--bs-gray-800);
    width: 12px;
  }

  .p-multiselect-chip .p-multiselect-label-container .p-multiselect-label {
    @extend .d-flex, .p-0, .column-gap-1;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    @extend .px-2, .py-1;
  }

  .p-multiselect-items {
    @extend .p-0, .m-0;
  }
}

.invisible-placeholder .p-placeholder {
  @extend .invisible;
}

.ffx-select {
  @extend .w-100;

  .p-dropdown-label {
    @extend .py-1;

    min-height: 32px;
  }

  .p-dropdown-trigger .p-icon-wrapper {
    color: var(--bs-gray-800);
    width: 12px;
  }

  .p-dropdown-items-wrapper .p-dropdown-items {
    @extend .p-0, .m-0;
  }
}

.ffx-select-panel {
  .p-dropdown-items-wrapper .p-dropdown-items .p-element .p-dropdown-item {
    min-height: 40px;
  }
}

.ffx-autocomplete {
  @extend .w-100;

  .p-autocomplete-multiple-container {
    @extend .d-flex, .form-control, .mb-0;
  }

  .p-autocomplete {
    height: 35px;
    @extend .d-inline-flex, .w-100;
  }

  .p-autocomplete-token {
    @extend .px-2, .py-0, .column-gap-2, .text-black;
  }

  .p-autocomplete-input-token {
    @extend .px-2, .py-0;
  }

  .p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-token
    .p-autocomplete-token-icon {
    @extend .d-flex, .m-0;
  }

  .p-autocomplete-items {
    @extend .p-0, .m-0;
  }
}

.ffx-checkbox {
  @extend .align-self-start;
}

.ffx-radiobutton {
  @extend .align-self-start;
}

.ffx-lookup {
  @extend .w-100;
}

// invalid border when element focused
.p-element.ng-dirty.ng-invalid {
  .p-inputnumber .p-inputtext:enabled:focus,
  .p-checkbox .p-checkbox-box.p-focus,
  .p-radiobutton .p-radiobutton-box.p-focus,
  .p-autocomplete.p-focus .p-autocomplete-multiple-container,
  .p-autocomplete.p-focus .p-autocomplete-input,
  .p-multiselect.p-focus,
  .p-dropdown.p-inputwrapper-focus {
    @extend .invalid-p-focus;
  }
}

.p-component {
  font-family: inherit;
}

p-autocomplete.ng-dirty.ng-invalid {
  .p-autocomplete-multiple-container {
    border-color: var(--bs-red);
  }
}

p-radiobutton.ng-dirty.ng-invalid {
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--bs-red);
  }
}

p-inputnumber.ng-dirty.ng-invalid {
  border-color: var(--bs-red);
}

.p-inputnumber {
  @extend .w-100, .p-0, .border-0;
}

.p-inputnumber .p-inputnumber-input {
  @extend .form-control, .border-0;
}

.p-overlay.p-component {
  z-index: 1090 !important;
  .p-autocomplete-items {
    margin: 0px;
    padding: 0px;
  }

  .p-dropdown-items {
    padding: 0px;
    margin: 0px;
  }

  .p-multiselect-items {
    padding: 0px;
    margin: 0px;
  }
}

.p-datatable-thead {
  z-index: 0;
}
