.common-stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.common-stepper-item-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
}

.circle-without-line-align-middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circle-container {
  position: relative;
  min-height: 44px;
  width: 44px;
}

.tabbable:focus {
  text-decoration-line: underline;
  text-decoration-color: var(--ffx-selected-outline);
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
  outline: none;
}
