/* Override the hover css when the input should be disabled/readonly but should still show validation errors */
input.isInputReadOnly {
  background-color: #f8f9fa !important;
  opacity: 1 !important;
  cursor: default !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: none !important;
}

.shapeSize {
  width: 22px;
  height: 22px;
}
